$base-color: #413c33;

@import 'syntax-highlighting';

$light-color: #c2c2c2;
$light-hover: adjust-color($light-color, $lightness: -10%);

$base-color: #413c33;
$hover-color: adjust-color($base-color, $lightness: -10%);

$br-color: #eee;
$bg-color: #fdfaf8;

:root {
	box-sizing: border-box;
	font-family: Helvetica,Arial,sans-serif;
	color: $base-color;
	background-color: $bg-color;
	text-rendering: optimizeLegibility;
	font-smoothing: antialiased;
}

body {
	margin: 0;
}

h1,h2,h3,h4,h5,h6 {
	margin: 0.5rem 0;
}
h1 {
	font-size: 2em;
}

.container,main,footer {
	max-width: 75rem;
	margin: auto;
	padding: 0.5rem 1rem;
}

header {
	height: 35rem;
	max-height: 75vh;
	overflow: hidden;
	background-color: #333;

	margin-bottom: 2em;
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;

	> .banner-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		object-fit: cover;
		object-position: center center;

		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
	}

	> .banner {
		position: relative;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1;
		align-self: stretch;

		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;

		> .container {
			padding-top: 0;
			padding-bottom: 0;
			display: flex;
			flex-wrap: wrap;
			line-height: 2;
		}

		a {
			padding-left: 0.5em;
			padding-right: 0.5em;
			color: $light-color;
			font-size: 1.2rem;
			display: flex;
			align-items: center;
			text-decoration: none;
			white-space: nowrap;
			z-index: 1;

			&:hover,&:active {
				color: $light-color;
				opacity: 0.8;
			}

			svg {
				width: 1em;
				height: 1em;
				> * {
					fill: $light-color;
				}
			}
		}

		.site-title {
			font-size: 2rem;
			padding-left: 0;
			padding-right: 0;
		}
	}

	.intro {
		font-size: 2.0rem;
		color: white;

		text-align: center;
		max-width: 25em;
		width: 85vw;
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;

		text-shadow: 0 0 1rem #3C3C3C;
	}
}

footer {
	opacity: 0.75;
	border-top: 1px solid #eee;
	padding-top: 1rem;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	text-align: center;
	font-style: italic;
}

.post-meta {
	font-size: 0.9rem;
	opacity: 0.5;
	margin: 0;
	margin-bottom: 0
}

a {
	color: #33a;
	text-decoration: none;
	&:active,&:hover {
		color: #00a;
	}
}
.post-list {
	border-bottom: 1px solid $br-color;
	margin-bottom: 2em;
	padding-bottom: 1em;
	&:last-child {
		border-bottom: none;
	}
}

pre, code {
	border: 1px solid $br-color;
	border-radius: .5rem;
	background-color: #eef;
}

code {
	padding: .1rem .5rem;
}

pre {
	padding: .5rem .75rem;
	overflow-x: auto;

	> code {
		border: 0;
		padding-right: 0;
		padding-left: 0;
	}
}

.banner-image-credits {
	position: absolute;
	bottom: 0;
	right: 0;
	line-height: 2;
	padding: 0 1.0rem 0 0.5rem;
	background-color: rgba(255, 255, 255, 0.75);

	border-top-left-radius: 0.5rem;
}

.spacer {
	flex-grow: 1;
}

article::after {
	content: '';
	display: block;
	clear: both;
}
